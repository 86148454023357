// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-animation-reel-tsx": () => import("./../../../src/pages/work/animation-reel.tsx" /* webpackChunkName: "component---src-pages-work-animation-reel-tsx" */),
  "component---src-pages-work-editing-reel-tsx": () => import("./../../../src/pages/work/editing-reel.tsx" /* webpackChunkName: "component---src-pages-work-editing-reel-tsx" */),
  "component---src-pages-work-reel-tsx": () => import("./../../../src/pages/work/reel.tsx" /* webpackChunkName: "component---src-pages-work-reel-tsx" */)
}

